import { isObject } from '@/libs/objects'
import dotProp from 'dot-prop'
import type { ValidationErrors } from '@/types/validator'

export const getErrorsStartingWith = (key: string, errors: ValidationErrors): ValidationErrors => {
  return Object.keys(errors).reduce((prev, currentError) => {
    return currentError.startsWith(key) ? { ...prev, [currentError]: errors[currentError] } : { ...prev }
  }, {})
}

export const getChipErrors = (startsWith: string, errors: ValidationErrors): ValidationErrors => {
  const field = `${startsWith}.`
  const selectedErrors = getErrorsStartingWith(field, errors)
  return mapErrors(field, '', selectedErrors)
}

export const mapErrors = (startsWith: string, to: string, errors: ValidationErrors): ValidationErrors => {
  return Object.keys(errors).reduce((prev, currentError) => {
    if (!currentError.startsWith(startsWith)) {
      return { ...prev, [currentError]: errors[currentError] }
    }

    const replacedError = currentError.replace(startsWith, to)
    return { ...prev, [replacedError || currentError]: errors[currentError] }
  }, {})
}

export const getSectionErrors = (
  sections: Record<string, string[]>,
  errors: ValidationErrors
): Record<string, boolean> => {
  return Object.entries(sections).reduce((prev, [key, fields]) => {
    return { ...prev, [key]: fields.some((field) => Boolean(errors[field])) }
  }, {})
}

export const getModelKeys = (model: any, key: string = '', deep: boolean = false): string[] => {
  const obj = key ? dotProp.get(model, key) : model
  const prefix = key ? `${key}.` : ''

  const modelKeys = []

  if (Array.isArray(obj)) {
    modelKeys.push(...obj.map((val, index) => `${prefix}${index}`))
  }

  if (isObject(obj)) {
    modelKeys.push(...Object.keys(obj).map((val) => `${prefix}${val}`))
  }

  if (modelKeys.length && deep) {
    const nestedKeys = modelKeys.reduce((prev: string[], current) => {
      return [...prev, ...getModelKeys(model, current, true)]
    }, [])
    return [...modelKeys, ...nestedKeys].filter(Boolean)
  }

  return modelKeys
}
