<template>
    <v-container>
        <div class="table_container">
            <div class="table_header">
                <v-row>
                    <v-col cols="10">
                        <h2>Last 12 Months</h2>
                    </v-col>
                    <v-col cols="2" class="d-flex align-content-start year_filter">
                        <span class="pt-2 pr-5">Year:</span>
                        <v-select class="pa-0 ma-0" v-model="selectedYear" :items="availableYear" label="Select Year"
                            multiple></v-select>
                    </v-col>
                </v-row>
            </div>
            <v-data-table :headers="headers" :items="filteredData" :sort-desc.sync="sortDesc" :sort-by.sync="sortBy"
                :item-class="customRowClass">
                <template v-slot:items="props">
                    <td class="custom-column">{{ props.item.Month }}</td>
                    <td class="custom-column">{{ props.item.GrossRevenue }}</td>
                    <td class="custom-column">{{ props.item.Revenue_Shipping }}</td>
                    <td class="custom-column">{{ props.item.Orders }}</td>
                    <td class="custom-column">{{ props.item.Products_Sold }}</td>
                    <td class="custom-column">{{ props.item.New_Customers }}</td>
                    <td class="custom-column">{{ props.item.Order_New_Customers }}</td>
                    <td class="custom-column">{{ props.item.Returninig_Customers }}</td>
                    <td class="custom-column">{{ props.item.Order_Returninig_Customers }}</td>
                    <td class="custom-column">{{ props.item.Total_customers }}</td>
                    <!-- Add more columns based on your data -->
                </template>
            </v-data-table>
        </div>
    </v-container>
</template>
  
<script>
import { defineComponent } from '@vue/composition-api';
// import VueApexCharts from 'vue-apexcharts';

export default defineComponent({
    name: 'LastMonthTable',
    data() {
        return {
            selectedMonth: [],
            selectedYear: [],
            sortBy: 'Month',
            sortDesc: false,
            data: [
                {
                    Month: "November 2023",
                    GrossRevenue: '$753,852.90',
                    Revenue_Shipping: '$756,842.50',
                    Orders: '680,142',
                    Products_Sold: '1,800',
                    New_Customers: '250',
                    Order_New_Customers: "80",
                    Returninig_Customers: "35",
                    Order_Returninig_Customers: "300",
                    Total_customers: "2,500"
                },
                {
                    Month: "October 2023",
                    GrossRevenue: '$730,432.90',
                    Revenue_Shipping: '$732,550.56',
                    Orders: '659,321',
                    Products_Sold: '1,300',
                    New_Customers: '200',
                    Order_New_Customers: "80",
                    Returninig_Customers: "50",
                    Order_Returninig_Customers: "300",
                    Total_customers: "1,700"
                },
                {
                    Month: "September 2023",
                    GrossRevenue: '$753,852.90',
                    Revenue_Shipping: '$756,842.50',
                    Orders: '680,142',
                    Products_Sold: '1,800',
                    New_Customers: '250',
                    Order_New_Customers: "80",
                    Returninig_Customers: "35",
                    Order_Returninig_Customers: "300",
                    Total_customers: "2,500"
                },
                {
                    Month: "August 2023",
                    GrossRevenue: '$730,432.90',
                    Revenue_Shipping: '$732,550.56',
                    Orders: '659,321',
                    Products_Sold: '1,300',
                    New_Customers: '200',
                    Order_New_Customers: "80",
                    Returninig_Customers: "50",
                    Order_Returninig_Customers: "300",
                    Total_customers: "1,700"
                },
                {
                    Month: "July 2023",
                    GrossRevenue: '$753,852.90',
                    Revenue_Shipping: '$756,842.50',
                    Orders: '680,142',
                    Products_Sold: '1,800',
                    New_Customers: '250',
                    Order_New_Customers: "80",
                    Returninig_Customers: "35",
                    Order_Returninig_Customers: "300",
                    Total_customers: "2,500"
                },
                {
                    Month: "June 2023",
                    GrossRevenue: '$730,432.90',
                    Revenue_Shipping: '$732,550.56',
                    Orders: '659,321',
                    Products_Sold: '1,300',
                    New_Customers: '200',
                    Order_New_Customers: "80",
                    Returninig_Customers: "50",
                    Order_Returninig_Customers: "300",
                    Total_customers: "1,700"
                },
                {
                    Month: "July 2022",
                    GrossRevenue: '$753,852.90',
                    Revenue_Shipping: '$756,842.50',
                    Orders: '680,142',
                    Products_Sold: '1,800',
                    New_Customers: '250',
                    Order_New_Customers: "80",
                    Returninig_Customers: "35",
                    Order_Returninig_Customers: "300",
                    Total_customers: "2,500"
                },
                {
                    Month: "June 2022",
                    GrossRevenue: '$730,432.90',
                    Revenue_Shipping: '$732,550.56',
                    Orders: '659,321',
                    Products_Sold: '1,300',
                    New_Customers: '200',
                    Order_New_Customers: "80",
                    Returninig_Customers: "50",
                    Order_Returninig_Customers: "300",
                    Total_customers: "1,700"
                }
                // Add more data objects for different Month
            ],
            headers: [
                { text: 'Month', value: 'Month', class: 'custom-header' },
                { text: 'Gross Revenue', value: 'GrossRevenue', class: 'custom-header' },
                { text: 'Revenue with Tax & Shipping', value: 'Revenue_Shipping', class: 'custom-header' },
                { text: 'Orders', value: 'Orders', class: 'custom-header' },
                { text: 'Products Sold', value: 'Products_Sold', class: 'custom-header' },
                { text: 'New Customers', value: 'New_Customers', class: 'custom-header' },
                { text: 'Order By New Customer', value: 'Order_New_Customers', class: 'custom-header' },
                { text: 'Returning Customers', value: 'Returninig_Customers', class: 'custom-header' },
                { text: 'Order By Returning Customers', value: 'Order_Returninig_Customers', class: 'custom-header' },
                { text: 'Total Customers', value: 'Total_customers', class: 'custom-header' }
                // Add more headers based on your data
            ],
        };
    },
    computed: {
        availableYear() {
            return [...new Set(this.data.map((item) => item.Month.split(' ')[1]))];
        },
        filteredData() {
            if (this.selectedMonth.length === 0 && this.selectedYear.length === 0) {
                return this.data;
            } else {
                return this.data.filter((item) =>
                    (this.selectedMonth.length === 0 || this.selectedMonth.includes(item.Month.split(' ')[0])) &&
                    (this.selectedYear.length === 0 || this.selectedYear.includes(item.Month.split(' ')[1]))
                );
            }
        },
    },
    methods: {
        customRowClass(item) {
            return {
                'custom-row': true,
                'custom-row-even': this.data.indexOf(item) % 2 === 0,
                'custom-row-odd': this.data.indexOf(item) % 2 !== 0,
            };
        },
    },
});
</script>
  
