import { SalesReportTypeEnum } from '@/types/reports/SaleReport'
import type {
  SalesReportGridSort,
  SalesReportGroupedGridSort,
  SalesReportItemGridSort,
  SalesReportItemGroupedGridSort
} from '@futureecom/futureecom-js/dist/services/order-service'

export const sorts: (string | SalesReportGridSort)[] = [
  '-billing_country',
  '-billing_region',
  '-created_at',
  '-created_by',
  '-created_from',
  '-customer_firstname',
  '-customer_lastname',
  '-discounts',
  '-items.name',
  '-items.sku',
  '-order_number',
  '-payment_method',
  '-shipping_country',
  '-shipping_method',
  '-shipping_region',
  '-status',
  '-summary.total_discount.amount',
  '-summary.total_due.amount',
  '-summary.total_items_count',
  '-summary.total_refunded.amount',
  '-summary.total_taxes.amount',
  '-taxes.code',
  '-taxes.rate',
  '-updated_at',
  'billing_country',
  'billing_region',
  'created_at',
  'created_by',
  'created_from',
  'customer_firstname',
  'customer_lastname',
  'discounts',
  'items.name',
  'items.sku',
  'order_number',
  'payment_method',
  'shipping_country',
  'shipping_method',
  'shipping_region',
  'status',
  'summary.total_discount.amount',
  'summary.total_due.amount',
  'summary.total_items_count',
  'summary.total_refunded.amount',
  'summary.total_taxes.amount',
  'taxes.code',
  'taxes.rate',
  'updated_at'
]

export const groupedSorts: (string | SalesReportGroupedGridSort)[] = [
  '-meta.items_count.avg',
  '-meta.items_count.max',
  '-meta.items_count.min',
  '-meta.items_count.sum',
  '-meta.total_discount.avg.amount',
  '-meta.total_discount.max.amount',
  '-meta.total_discount.min.amount',
  '-meta.total_discount.sum.amount',
  '-meta.total_due.avg.amount',
  '-meta.total_due.max.amount',
  '-meta.total_due.min.amount',
  '-meta.total_due.sum.amount',
  '-meta.total_refunded.avg.amount',
  '-meta.total_refunded.max.amount',
  '-meta.total_refunded.min.amount',
  '-meta.total_refunded.sum.amount',
  '-meta.total_taxes.avg.amount',
  '-meta.total_taxes.max.amount',
  '-meta.total_taxes.min.amount',
  '-meta.total_taxes.sum.amount',
  'meta.items_count.avg',
  'meta.items_count.max',
  'meta.items_count.min',
  'meta.items_count.sum',
  'meta.total_discount.avg.amount',
  'meta.total_discount.max.amount',
  'meta.total_discount.min.amount',
  'meta.total_discount.sum.amount',
  'meta.total_due.avg.amount',
  'meta.total_due.max.amount',
  'meta.total_due.min.amount',
  'meta.total_due.sum.amount',
  'meta.total_refunded.avg.amount',
  'meta.total_refunded.max.amount',
  'meta.total_refunded.min.amount',
  'meta.total_refunded.sum.amount',
  'meta.total_taxes.avg.amount',
  'meta.total_taxes.max.amount',
  'meta.total_taxes.min.amount',
  'meta.total_taxes.sum.amount'
]

export const itemsSorts: (string | SalesReportItemGridSort)[] = [
  '-created_at',
  '-id',
  '-name',
  '-quantity_refunded',
  '-quantity',
  '-sku',
  '-total_discount.amount',
  '-total_gross.amount',
  '-total_taxes.amount',
  '-total.amount',
  '-updated_at',
  'created_at',
  'id',
  'name',
  'quantity_refunded',
  'quantity',
  'sku',
  'total_discount.amount',
  'total_gross.amount',
  'total_taxes.amount',
  'total.amount',
  'updated_at'
]

export const groupedItemsSorts: (string | SalesReportItemGroupedGridSort)[] = [
  '-meta.quantity.avg',
  '-meta.quantity.max',
  '-meta.quantity.min',
  '-meta.quantity.sum',
  '-meta.total_discount.avg.amount',
  '-meta.total_discount.max.amount',
  '-meta.total_discount.min.amount',
  '-meta.total_discount.sum.amount',
  '-meta.total_gross.avg.amount',
  '-meta.total_gross.max.amount',
  '-meta.total_gross.min.amount',
  '-meta.total_gross.sum.amount',
  '-meta.total_taxes.avg.amount',
  '-meta.total_taxes.max.amount',
  '-meta.total_taxes.min.amount',
  '-meta.total_taxes.sum.amount',
  '-meta.total.avg.amount',
  '-meta.total.max.amount',
  '-meta.total.min.amount',
  '-meta.total.sum.amount',
  'meta.quantity.avg',
  'meta.quantity.max',
  'meta.quantity.min',
  'meta.quantity.sum',
  'meta.total_discount.avg.amount',
  'meta.total_discount.max.amount',
  'meta.total_discount.min.amount',
  'meta.total_discount.sum.amount',
  'meta.total_gross.avg.amount',
  'meta.total_gross.max.amount',
  'meta.total_gross.min.amount',
  'meta.total_gross.sum.amount',
  'meta.total_taxes.avg.amount',
  'meta.total_taxes.max.amount',
  'meta.total_taxes.min.amount',
  'meta.total_taxes.sum.amount',
  'meta.total.avg.amount',
  'meta.total.max.amount',
  'meta.total.min.amount',
  'meta.total.sum.amount'
]

export const getSalesReportSorts = (type: SalesReportTypeEnum, isGrouped = false) => {
  if (type === SalesReportTypeEnum.ITEMS) {
    return isGrouped ? groupedItemsSorts : itemsSorts
  }
  return isGrouped ? groupedSorts : sorts
}
