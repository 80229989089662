//TODO: migrate routes such as stores, organisations, translation languages and plugins
import type { Route } from '@/types/Route'

const routes: Route[] = [
  {
    path: 'dynamic/attributes/__',
    name: 'dynamic-attribute-create',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-attributes" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/attributes/DynamicAttributeCreate.vue'
      )
  },
  {
    path: 'dynamic/attributes/:id',
    name: 'dynamic-attribute-update',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-attributes" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/attributes/DynamicAttributeUpdate.vue'
      )
  },
  {
    path: 'dynamic/attributes',
    name: 'dynamic-attributes',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-attributes" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/attributes/DynamicAttributes.vue'
      ),
    meta: {
      scopes: ['read,dynamic\\attribute']
    }
  },
  {
    path: 'dynamic/groups/__',
    name: 'dynamic-group-create',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-group" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/groups/DynamicGroupCreate.vue'
      )
  },
  {
    path: 'dynamic/groups/:id',
    name: 'dynamic-group-update',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-group" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/groups/DynamicGroupUpdate.vue'
      )
  },
  {
    path: 'dynamic/groups',
    name: 'dynamic-groups',
    component: () =>
      import(
        /* webpackChunkName: "dynamic-groups" */
        /* webpackMode: "lazy" */
        '@/views/settings/dynamic/groups/DynamicGroups.vue'
      )
  }
]

export default routes
