import { useAuth } from '@/composable/useAuth'
import type { NavigationGuardNext, Route } from 'vue-router'

export default (to: Route, from: Route, next: NavigationGuardNext): void => {
  const { accessToken, refreshToken, userType } = useAuth()

  if (refreshToken.value) {
    next()
    return
  }

  if (!accessToken.value || !['admin', 'super_admin'].includes(userType.value || '')) {
    next({ name: 'login', params: { redirect: to.fullPath } })
    return
  }

  next()
}
