import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('scrollable',{scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"d-flex justify-space-between"},[_c('div',{staticClass:"d-flex flex-column"},[_vm._t("title",function(){return [_c('span',[_vm._v(_vm._s(_vm.title))])]}),_vm._t("subtitle")],2),_vm._t("actions")],2),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(ref){
var heightExceeded = ref.heightExceeded;
return [_c('div',{staticClass:"px-4 position_relative"},[_vm._t("scrollable.content.top"),_c('div',[_c('resource-grid',_vm._b({on:{"onParamsChange":function($event){_vm.gridParams = $event},"update:selectedItems":function($event){return _vm.$emit('update:selected-items', $event)}},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData || {})]}}})],null,true)},'resource-grid',Object.assign({}, {value: _vm.gridParams,
            options: _vm.gridOptions,
            items: _vm.grid.data,
            selectedItems: _vm.selectedItems,
            hideDefaultFooter: true},
            _vm.gridProps),false))],1),_c(VDivider),(!heightExceeded)?_c('div',{class:_vm.footerClasses},[_vm._t("grid.footer.actions"),_vm._t("grid.footer.pagination",function(){return [(_vm.grid.meta)?_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.gridParams),callback:function ($$v) {_vm.gridParams=$$v},expression:"gridParams"}}):_vm._e()]})],2):_vm._e()],2)]}},{key:"sticky-bottom",fn:function(ref){
          var heightExceeded = ref.heightExceeded;
return [(heightExceeded)?_c('div',[_c(VDivider),_c(VFooter,{class:_vm.footerClasses},[_vm._t("grid.footer.actions"),_vm._t("grid.footer.pagination",function(){return [(_vm.grid.meta)?_c('table-pagination',{attrs:{"meta":_vm.grid.meta},model:{value:(_vm.gridParams),callback:function ($$v) {_vm.gridParams=$$v},expression:"gridParams"}}):_vm._e()]})],2)],1):_vm._e()]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }