<template>
  <div class="test">
    <p v-if="fact">{{ fact.customer_firstname }}</p> 
  </div>
</template>
<script>

import { ReportViewTypeEnum, SalesReportTypeEnum } from '@/types/reports/SaleReport'
import { SaleRouteNameEnums } from '@/routes/saleRoutes'
import { computed, defineComponent, reactive } from '@vue/composition-api'
import { getDateRange } from '@/libs/domains/report/formatters'
import { getSalesReportFilters, mapGroupedByToFilters } from '@/libs/domains/report/filters'
import { getSalesReportHeaders } from '@/libs/domains/report/headers'
import { getSalesReportType } from '@/libs/domains/report/reportType'
import { getSalesReportViewType } from '@/libs/domains/report/viewType'
import { gridTemplate } from '@/libs/tables/grid'
import {
  isSalesReportGroupedItemsGridRequest,
  isSalesReportGroupedOrdersGridRequest,
  isSalesReportItemsGridRequest,
  isSalesReportOrdersGridRequest
} from '@/libs/domains/report/guards'
import { parseQueryParams, stringifyQueryParams } from '@/libs/queryParamsSerializer'
import { useCrudChecker } from '@/composable/useCrudChecker'
import { useI18n } from 'vue-i18n-bridge'
import { useQueryFromUrl } from '@/composable/useQueryFromUrl'
import GridLayout from '@/components/layouts/GridLayout.vue'
import RtlChevronIcon from '@/components/common/RtlChevronIcon.vue'
import SalesReportExportButton from '@/components/domains/reports/SalesReportExportButton.vue'
import SalesReportGroupedByLabels from '@/components/domains/reports/queryInputs/SalesReportGroupedByLabels.vue'
import SalesReportQueryInputs from '@/components/domains/reports/queryInputs/SalesReportQueryInputs.vue'
import SalesReportSummary from '@/components/domains/reports/SalesReportSummaryTotal.vue'
import useApi from '@/composable/useApi'


export default defineComponent({
  name: 'Api',

  components: {
    SalesReportExportButton,
    SalesReportGroupedByLabels,
    SalesReportQueryInputs,
    RtlChevronIcon,
    SalesReportSummary,
    GridLayout
  },

  props: {
    msg: String,
  },
  data() {
    return {
      fact: "",
    };
  },
  methods: {
    fetchData() {
      fetch('https://qualifiedh.api.us-01.futureecom.app/orders/orders', {
        method: "GET",
      })
        .then((response) => {
          response.json().then((data) => {
            this.fact = data[0].fact;
          });
        })
        .catch((err) => {
          console.error(err);
        });
    },
  },

  

});
</script>
<style>
button {
  padding: 12px 32px;
  font-size: 16px;
  border-radius: 8px;
}
</style>