<template>
    <v-container>
        <div class="table_container">
            <div class="table_header">
                <v-row>
                    <v-col cols="10">
                        <h2>Comparison Chart</h2>
                    </v-col>
                    <v-col cols="2" class="d-flex align-content-start year_filter">
                        <span class="pt-1 pr-5">Year:</span>
                        <v-select class="pa-0 ma-0" label="Select Year"
                            multiple></v-select>
                    </v-col>
                </v-row>
            </div>
            <v-data-table :headers="headers" :items="data" :sort-desc.sync="sortDesc" :sort-by.sync="sortBy" item-key="KPI" :item-class="customRowClass">
                <template v-slot:items="props">
                    <td class="custom-column">{{ props.item.KPI }}</td>
                    <td class="custom-column">{{ props.item.Q42023 }}</td>
                    <td class="custom-column">{{ props.item.Q42022 }}</td>
                    <td class="custom-column">{{ props.item.P_Difference }}</td>
                    <td class="custom-column">{{ props.item.H_Difference }}</td>
                    <!-- Add more columns based on your data -->
                </template>
            </v-data-table>
        </div>
    </v-container>
</template>
  
<script>
import { defineComponent } from '@vue/composition-api';
// import VueApexCharts from 'vue-apexcharts';

export default defineComponent({
    name: 'ComparisonChartTable',
    data() {
        return {
            sortBy: 'KPI',
            sortDesc: false,
            // selectedYear: [],
            data: [
                {
                    KPI: "Gross Revenue",
                    Q42023: '$789,852.90',
                    Q42022: '$756,842.50',
                    P_Difference: '+3.5%',
                    H_Difference: '$3,120'
                },{
                    KPI: "Revenue with Tax & Shipping",
                    Q42023: '$792,234.45',
                    Q42022: '$758,442.32',
                    P_Difference: '+2.3%',
                    H_Difference: '$2,140'
                },{
                    KPI: "Orders",
                    Q42023: '$792,234.45',
                    Q42022: '$758,442.32',
                    P_Difference: '+2.3%',
                    H_Difference: '$2,140'
                },{
                    KPI: "Products Sold",
                    Q42023: '1,500',
                    Q42022: '1,320',
                    P_Difference: '+3.5%',
                    H_Difference: '$1,200'
                },{
                    KPI: "New Customers",
                    Q42023: '877',
                    Q42022: '689',
                    P_Difference: '+5%',
                    H_Difference: '$650'
                }
            ],
            headers: [
                { value: 'KPI', text: 'KPI', class: 'custom-header' },
                { value: 'Q42023', text: 'Q4 2023', class: 'custom-header' },
                { value: 'Q42022', text: 'Q4 2022', class: 'custom-header' },
                { value: 'P_Difference', text: '% Difference', class: 'custom-header' },
                { value: 'H_Difference', text: '# Difference', class: 'custom-header' }
            ],
        };
    },
    // computed: {
    //     filteredData() {
    //         if (this.selectedYear.length === 0) {
    //             return this.data;
    //         } else {
    //             return this.data.filter(item => {
    //                 // Extracting year from Q42023 assuming the format remains consistent
    //                 const year = item.Q42023.split(' ')[1];
    //                 return this.selectedYear.includes(year);
    //             });
    //         }
    //     },
    // },
    methods: {
        customRowClass(item) {
            return {
                'custom-row': true,
                'custom-row-even': this.data.indexOf(item) % 2 === 0,
                'custom-row-odd': this.data.indexOf(item) % 2 !== 0,
            };
        }, 
    },
});
</script>
  
