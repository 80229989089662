import { cookieStrategy } from '@/libs/tokenManager/strategies/cookie'
import { envs } from '@/config/envs'
import { inMemoryStrategy } from '@/libs/tokenManager/strategies/inMemory'
import { localStorageStrategy } from '@/libs/tokenManager/strategies/localStorage'
import { sessionStorageStrategy } from '@/libs/tokenManager/strategies/sessionStorage'
import type { TokenDriver } from '@/types/TokenDriver'

export const getDriverStrategy = (driver: string): TokenDriver => {
  const drivers: Record<string, TokenDriver> = {
    'session': sessionStorageStrategy,
    'localStorage': localStorageStrategy,
    'cookie': cookieStrategy,
    'in-memory': inMemoryStrategy
  }

  return drivers[driver] || drivers['in-memory']
}

export const tokenDriver = () => ({ ...getDriverStrategy(envs().TOKEN.STRATEGY || '') })
