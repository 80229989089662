import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"bg_primary text-white card_box"},[_c(VCardTitle,{staticClass:"d-flex flex-nowrap mail_title"},[_c('img',{attrs:{"src":_vm.icon,"alt":"Icon"}}),_c('span',{staticClass:"font-weight-regular"},[_vm._v(_vm._s(_vm.title))])]),(_vm.price)?_c(VCardSubtitle,{staticClass:"text-white price_text font-weight-bold"},[_vm._v(_vm._s(_vm.price))]):_vm._e(),_c(VContainer,[_c(VRow,{staticClass:"d-flex flex-nowrap"},[_c(VCol,{staticClass:"d-flex flex-nowrap",attrs:{"cols":"4"}},[_c('span',[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"18","height":"17","viewBox":"0 0 18 17","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_562_724)"}},[_c('path',{attrs:{"d":"M13.3062 4.91307C13.3428 4.44248 12.9894 4.04931 12.5176 4.03569L6.47847 3.86138C6.00667 3.84776 5.59384 4.21882 5.55723 4.6894C5.52063 5.15998 5.87402 5.55316 6.34582 5.56677L10.3261 5.67978L4.08691 11.2877C3.72696 11.6112 3.68508 12.1495 3.99321 12.4924C4.30133 12.8352 4.84108 12.8507 5.20104 12.5272L11.4403 6.91933L11.1316 10.8873C11.095 11.3579 11.4484 11.7511 11.9202 11.7647C12.392 11.7783 12.8049 11.4072 12.8415 10.9366L13.31 4.91318L13.3062 4.91307Z","fill":"white"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_562_724"}},[_c('rect',{attrs:{"width":"10","height":"14","fill":"white","transform":"translate(10.4121) rotate(48.0505)"}})])])])]),(_vm.growth)?_c(VCardText,{staticClass:"text-white pa-0"},[_vm._v(_vm._s(_vm.growth))]):_vm._e()],1),_c(VCol,{attrs:{"cols":"8"}},[(_vm.thisweek)?_c(VCardText,{staticClass:"text-white w-75 pa-0"},[_c('span',[_vm._v(_vm._s(_vm.thisweek))])]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }