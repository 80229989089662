import { default as VueRouter } from '@intlify/vue-router-bridge'
import { decodeRouteQuery } from '@/libs/router/parseRouteQuery'
import { filterRouteQuery as filter } from '@/libs/router/stringifyRouteQuery'
import { parse, stringify } from 'qs'
import { routes } from '@/routes'
import Vue from 'vue'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  stringifyQuery: (query?: any): string => stringify(query, { encode: false, addQueryPrefix: true, filter }),
  parseQuery: (query: string): Record<string, unknown> => parse(query, { decoder: decodeRouteQuery }),
  routes
})

export default router
