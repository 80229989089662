import { code, codes } from 'currency-codes'
import { computed } from '@vue/composition-api'
import { useSettings } from '@/composable/repositories/useSettings'
import type { GeneralSettingsCurrency, LocaleSettings } from '@futureecom/futureecom-js/dist/services/setting-service'

export const currenciesList: string[] = codes()

export const useCurrency = () => {
  const { getSettings } = useSettings()

  const storeCurrency = computed(() => {
    return getSettings<GeneralSettingsCurrency['default']>('general.currency.default').value || 'USD'
  })

  const storeLocale = computed(() => {
    return getSettings<LocaleSettings['default']>('general.locale.default').value || 'en-US'
  })

  const getCurrencySymbol = (currency: string): string => {
    try {
      return Intl.NumberFormat('en', { style: 'currency', currency })
        .format(0)
        .replace(/\d+([,.]\d+)?/g, '')
    } catch (err: any) {
      console.error(err.message)
      return ''
    }
  }

  const storeCurrencySymbol = computed((): string => getCurrencySymbol(storeCurrency.value))

  const getCurrencyDecimalPoint = (currency?: string): number => {
    return code(currency || storeCurrency.value)?.digits || 0
  }

  return {
    storeCurrencySymbol,
    storeCurrency,
    storeLocale,
    getCurrencySymbol,
    getCurrencyDecimalPoint
  }
}
