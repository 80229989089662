import { configureApi } from '@/libs/api'
import { getClientId, getOrganisationId } from '@futureecom/futureecom-js/dist/helpers/auth/auth-helpers'
import { useAuth } from '@/composable/useAuth'
import type { NavigationGuardNext, Route } from 'vue-router'

export default async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  const { accessToken } = useAuth()
  const clientId: string | undefined = getClientId(accessToken.value)
  const tokenOrg: number | undefined = getOrganisationId(accessToken.value)

  configureApi(clientId, tokenOrg)

  next()
}
