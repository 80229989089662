import { getOrganisationId } from '@futureecom/futureecom-js/dist/helpers/auth/auth-helpers'
import { isOverviewType } from '@/libs/isOverview'
import { useAuth } from '@/composable/useAuth'
import { useOrganisations, zeroOrganisation } from '@/composable/repositories/useOrganisations'
import type { NavigationGuardNext, Route } from 'vue-router'

export default async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  const { accessToken, isSuperAdmin } = useAuth()
  const { organisation } = to.params
  const { currentOrganisation, collection: organisations, fetchOrganisations } = useOrganisations()

  const organisationId = Number(organisation)

  if (isSuperAdmin.value && getOrganisationId(accessToken.value) === 0) {
    await fetchOrganisations()
  }

  if (organisationId === 0 && isOverviewType()) {
    currentOrganisation.value = zeroOrganisation
  }

  if (organisationId > 0 && isOverviewType()) {
    const foundOrg = organisations.value.find((item) => item.id === organisationId)
    currentOrganisation.value = foundOrg || organisations.value[0] || zeroOrganisation
  }

  next()
}
