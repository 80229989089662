<script>
import { useStores } from '@/composable/repositories/useStores';
export default {
  data() {
    return {
      currentStore: useStores().currentStore,
      selectedColor: '',
    };
  },
  mounted() {
    this.changeThemeColor();
  },
  methods: {
    changeThemeColor() {
     
      if (this.currentStore.value.color) {
        this.$vuetify.theme.themes.light.primary = this.currentStore.value.color;
      }
    }
  }
}
</script>
