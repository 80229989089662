import { getModelKeys } from '@/composable/validator/utils'
import type { RuleDefinitions } from '@/types/validator'

export const WILDCARD = '*'

export const extendWildcardRules = <T>(model: Partial<T>, rules: RuleDefinitions): RuleDefinitions => {
  return Object.entries(rules).reduce((prev, [key, value]) => {
    if (!key.includes(WILDCARD)) {
      return { ...prev, [key]: value }
    }

    return {
      ...prev,
      ...convertWildcardToTokens(model, key).reduce((prev, curr) => ({ ...prev, [curr]: value }), {})
    }
  }, {})
}

export const convertWildcardToTokens = (model: any, key: string): string[] => {
  if (!key.includes('*')) {
    return []
  }

  if (key.endsWith('*')) {
    return getModelKeys(model, key.replaceAll('.*', '').replaceAll('*', ''), true)
  }

  const splitAsterisks = key.split('.')

  const firstAsteriskIndex = splitAsterisks.findIndex((item) => item === `${WILDCARD}`)
  const mergedWithFirstAsterisk = splitAsterisks.slice(0, firstAsteriskIndex + 1).join('.')
  const firstTokenWithoutAsterisk = mergedWithFirstAsterisk.replace(`.${WILDCARD}`, '')

  const mergedTokens: string[] = getModelKeys(model, firstTokenWithoutAsterisk)

  const leftTokens = key.replace(`${mergedWithFirstAsterisk}`, '')

  if (!leftTokens) {
    return mergedTokens
  }

  if (leftTokens && !leftTokens.includes('*')) {
    return mergedTokens.map((item) => `${item}${leftTokens}`)
  }

  return mergedTokens
    .map((item) => `${item}${leftTokens}`)
    .reduce((prev: string[], current) => [...prev, ...convertWildcardToTokens(model, current)], [])
}
