import { computed, ref } from '@vue/composition-api'
import { envs } from '@/config/envs'
import { getUserType } from '@futureecom/futureecom-js/dist/helpers/auth/auth-helpers'
import { tokenDriver } from '@/libs/tokenManager/driver'
import type { Token } from '@futureecom/futureecom-js'

export const useAuth = () => {
  const { TOKEN } = envs()
  const driver = tokenDriver()

  const [organisation, store] = (driver.get(TOKEN.REFRESH_TENANCY_KEY) || '').split('-')

  const accessToken = ref<Token | undefined>(driver.get(TOKEN.ACCESS_TOKEN_KEY))
  const refreshToken = ref<Token | undefined>(driver.get(TOKEN.REFRESH_TOKEN_KEY))
  const refreshTenancyKeys = ref<{ organisation?: number; store?: number } | undefined>({
    organisation: Number(organisation || 0),
    store: store ? Number(store) : undefined
  })

  const userType = computed(() => getUserType(accessToken.value))
  const isSuperAdmin = computed<boolean>(() => userType.value === 'super_admin')

  const setAccessToken = (val: Token) => {
    accessToken.value = val
    driver.set(TOKEN.ACCESS_TOKEN_KEY, val)
  }

  const setRefreshToken = (val: Token) => {
    refreshToken.value = val
    driver.set(TOKEN.REFRESH_TOKEN_KEY, val)
  }

  const setRefreshTenancyKeys = (val: { organisation?: number; store?: number } | undefined) => {
    if (!val || val.organisation === undefined) {
      refreshTenancyKeys.value = undefined
      driver.set(TOKEN.REFRESH_TENANCY_KEY, undefined)
      return
    }
    const { organisation, store } = val
    refreshTenancyKeys.value = { organisation, store }
    driver.set(TOKEN.REFRESH_TENANCY_KEY, `${organisation}-${store || ''}`)
  }

  return {
    userType,
    isSuperAdmin,
    accessToken: computed(() => accessToken.value),
    refreshToken: computed(() => refreshToken.value),
    refreshTenancyKeys: computed(() => refreshTenancyKeys.value),
    setAccessToken,
    setRefreshToken,
    setRefreshTenancyKeys
  }
}
