import { isOverviewType } from '@/libs/isOverview'
import { useOrganisations } from '@/composable/repositories/useOrganisations'
import { useStores } from '@/composable/repositories/useStores'
import type { NavigationGuardNext, Route } from 'vue-router'

export default (to: Route, _from: Route, next: NavigationGuardNext): void => {
  const { currentStore } = useStores()
  const { isOverviewMode } = useOrganisations()

  const apps = to.meta?.['apps']

  if (!apps) {
    next()
    return
  }

  if (apps[0] === 'console' && currentStore.value) {
    next()
    return
  }

  if (apps[0] === 'overview' && isOverviewType() && isOverviewMode.value) {
    next()
    return
  }

  next({ name: 'dashboard' })
}
