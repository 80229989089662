import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VForm,{attrs:{"id":"filters-grid"},on:{"submit":function($event){$event.preventDefault();return _vm.apply.apply(null, arguments)}}},[_c('scrollable',{attrs:{"height":"100vh"},scopedSlots:_vm._u([{key:"sticky",fn:function(){return [_c(VCard,{attrs:{"flat":""}},[_c(VCardTitle,{staticClass:"py-2 d-flex justify-space-between"},[_vm._t("header",function(){return [_vm._t("before-title"),_c('span',[_vm._v(_vm._s(_vm.$t('Filters')))]),_c(VTextField,{staticClass:"ms-16 my-2",attrs:{"label":_vm.$t('Search for filter'),"outlined":"","dense":"","hide-details":"","append-icon":"filter_list"},model:{value:(_vm.searchFilter),callback:function ($$v) {_vm.searchFilter=$$v},expression:"searchFilter"}},[_vm._t("after-title")],2)]})],2),_c(VDivider)],1)]},proxy:true},{key:"scrollable-content",fn:function(){return [_c('div',{staticClass:"d-flex flex-column"},_vm._l((_vm.visibleFilters),function(option,index){return _c('div',{key:index},[_vm._t(("filter." + (option.code)),function(){return [_c('filter-grid-input',{staticClass:"pa-3",attrs:{"definition":option},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}})]}),_vm._t("filters"),_c(VDivider)],2)}),0)]},proxy:true},{key:"sticky-bottom",fn:function(){return [_c(VDivider),_c(VCard,{staticClass:"d-flex justify-space-around mx-0 py-4",attrs:{"flat":""}},[_vm._t("actions.before"),_vm._t("actions",function(){return [_c(VBtn,{attrs:{"depressed":""},on:{"click":function($event){return _vm.$emit('clear')}}},[_vm._v(" "+_vm._s(_vm.$t('Clear'))+" ")]),_c(VBtn,{attrs:{"color":"primary","depressed":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Apply'))+" ")])]}),_vm._t("actions.after")],2)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }