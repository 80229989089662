import { computed, ref } from '@vue/composition-api'
import { useApi } from '@/composable/useApi'
import type { Store } from '@futureecom/futureecom-js/dist/services/setting-service'

const collection = ref<Store[]>([])
const currentStore = ref<Store>()
const loadingStores = ref(false)

export const useStores = () => {
  const fetchStores = async (orgId: number): Promise<void> => {
    loadingStores.value = true

    await useApi()
      .usingOrganisation(orgId)
      .settings.stores(orgId)
      .then((value) => (collection.value = [...value]))
      .finally(() => (loadingStores.value = false))
  }

  const setStores = (stores: Store[]) => {
    collection.value = [...stores]
  }

  const initStore = async (organisationId: number, storeId?: number) => {
    currentStore.value = collection.value.find((item) => item.id === storeId)

    if (currentStore.value && currentStore.value.id === storeId) {
      return
    }

    await fetchStores(organisationId)
    currentStore.value = collection.value.find((item) => item.id === storeId) || collection.value[0]
  }

  return {
    currentStore,
    collection,
    loadingStores: computed(() => loadingStores.value),
    fetchStores,
    setStores,
    initStore
  }
}
