import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex flex-column"},[_vm._t("filters",function(){return [(_vm.options.filters)?[_c('div',{staticClass:"d-flex justify-space-between"},[_c('filters-chips',{attrs:{"value":_vm.value,"definitions":_vm.options.filters},on:{"input":_vm.onFiltersApplied}}),_c(VBtn,{staticClass:"mt-3",attrs:{"depressed":"","small":""},on:{"click":function($event){_vm.openFilters = !_vm.openFilters}}},[_c('span',[_vm._v(_vm._s(_vm.$t('Filters')))]),_c(VIcon,{attrs:{"right":""}},[_vm._v("filter_alt")])],1)],1),_c(VDivider,{staticClass:"my-4"})]:_vm._e()]}),(_vm.options.filters)?_vm._t("grid.filters",function(){return [_c('filters-grid-drawer',{attrs:{"value":_vm.value,"open":_vm.openFilters,"definitions":_vm.options.filters},on:{"update:open":function($event){_vm.openFilters=$event},"input":_vm.onFiltersApplied},scopedSlots:_vm._u([_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(("grid.filter." + name),null,null,slotData || {})]}}})],null,true)})]}):_vm._e(),_c(VDataTable,_vm._b({staticClass:"v-data-table-shadowless",attrs:{"loading":_vm.options.loading,"headers":_vm.options.headers,"items":_vm.items,"item-key":"id","fixed-header":"","footer-props":{ 'items-per-page-text': _vm.$t('Rows per page') },"options":_vm.dataTableOptions,"server-items-length":_vm.options.meta ? _vm.options.meta.total : undefined},on:{"update:options":_vm.onDataTableOptionsUpdate},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getFormattedTime(item.created_at)))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getFormattedTime(item.updated_at)))])]}},_vm._l((_vm.$scopedSlots),function(_,name){return {key:name,fn:function(slotData){return [_vm._t(name,null,null,slotData || {})]}}})],null,true),model:{value:(_vm.currentSelectedItems),callback:function ($$v) {_vm.currentSelectedItems=$$v},expression:"currentSelectedItems"}},'v-data-table',_vm.$attrs,false))],2)}
var staticRenderFns = []

export { render, staticRenderFns }