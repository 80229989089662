import { useCurrency } from '@/composable/repositories/useCurrency'
import Big from 'big.js'
import type { Money } from '@futureecom/futureecom-js'

export const useMoney = () => {
  const { storeCurrency, storeLocale, getCurrencyDecimalPoint } = useCurrency()

  const fromMoneyAmount = (amount: string | number, currency?: string): string => {
    const value = Number(amount)
    const position = getCurrencyDecimalPoint(currency)
    const divider = Number(new Big(10).pow(position).toString())
    return new Big(value).div(divider).toString()
  }

  const toMoneyAmount = (amount: string | number, currency?: string): string => {
    const position = getCurrencyDecimalPoint(currency)
    const decimalCut = Math.pow(10, position)
    const value = Math.trunc(Number(`${amount}`.replaceAll(',', '.')) * decimalCut) / decimalCut
    const divider = Number(new Big(10).pow(position).toString())
    return new Big(value).mul(divider).toString()
  }

  const toMoney = (amount: string | number, currency?: string): Money => ({
    amount: toMoneyAmount(amount),
    currency: currency || storeCurrency.value
  })

  const formatMoneyWithCurrency = (value: Money, multiplier = 1): string => {
    if (!value?.amount) {
      value.amount = 0
    }

    const totalAmount = new Big(value.amount).mul(multiplier).toString()

    try {
      const amount = fromMoneyAmount(totalAmount, value.currency)
      const intlOptions = { style: 'currency', currency: value.currency || storeCurrency.value }
      return new Intl.NumberFormat(storeLocale.value, intlOptions).format(parseFloat(`${amount}`))
    } catch (err: any) {
      console.error(err.message)
      const amount = fromMoneyAmount(totalAmount, 'USD')
      return new Intl.NumberFormat(storeLocale.value, { minimumFractionDigits: 2 }).format(parseFloat(`${amount}`))
    }
  }

  return {
    fromMoneyAmount,
    toMoneyAmount,
    toMoney,
    withStoreCurrency: formatMoneyWithCurrency
  }
}
