import type { Route } from '@/types/Route'

const routes: Route[] = [
  {
    path: 'campaigns',
    name: 'promotions',
    component: () =>
      import(
        /* webpackChunkName: "promotions" */
        /* webpackMode: "lazy" */
        '@/views/promotions/Promotions.vue'
      ),
    meta: {
      scopes: ['read,promotions\\promotion']
    }
  },
  {
    path: 'campaigns/__/:option?',
    name: 'promotion-create',
    component: () =>
      import(
        /* webpackChunkName: "promotions" */
        /* webpackMode: "lazy" */
        '@/views/promotions/PromotionCreate.vue'
      ),
    meta: {
      scopes: ['read,promotions\\promotion']
    }
  },
  {
    path: 'campaigns/:id/:option?',
    name: 'promotion-update',
    component: () =>
      import(
        /* webpackChunkName: "promotions" */
        /* webpackMode: "lazy" */
        '@/views/promotions/PromotionUpdate.vue'
      ),
    meta: {
      scopes: ['read,promotions\\promotion']
    }
  }
]

export default routes
