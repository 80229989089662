import cookies from 'browser-cookies'
import type { Token } from '@futureecom/futureecom-js'
import type { TokenDriver } from '@/types/TokenDriver'

const tokens = new Map()

export const inMemoryStrategy: TokenDriver = {
  get: (tokenName: string) => tokens.get(tokenName) || undefined,
  set: (tokenName: string, value: Token) => (value ? tokens.set(tokenName, value) : cookies.erase(tokenName))
}
