export const envs = () => {
  const TOKEN: Record<'STRATEGY' | 'ACCESS_TOKEN_KEY' | 'REFRESH_TOKEN_KEY' | 'REFRESH_TENANCY_KEY', string> = {
    STRATEGY: process.env.VUE_APP_TOKEN_STRATEGY || 'in-memory',
    ACCESS_TOKEN_KEY: process.env.VUE_APP_ACCESS_TOKEN_COOKIE_KEY || 'access_token',
    REFRESH_TOKEN_KEY: process.env.VUE_APP_REFRESH_TOKEN_COOKIE_KEY || 'refresh_token',
    REFRESH_TENANCY_KEY: process.env.VUE_APP_REFRESH_TENANCY_KEY || 'refresh_tenancy'
  }

  const API = {
    URL: process.env.VUE_APP_API_BASEURL || '',
    CLIENT_ID: process.env.VUE_APP_CLIENT_ID || '',

    ORGANISATION_ID: process.env.VUE_APP_ORGANISATION_ID ? Number(process.env.VUE_APP_ORGANISATION_ID) : undefined,
    STORE_ID: process.env.VUE_APP_STORE_ID ? Number(process.env.VUE_APP_STORE_ID) : undefined,
    WS_URL: process.env.VUE_APP_WS_URL || ''
  }

  const APP = {
    TYPE: process.env.VUE_APP_APPLICATION_TYPE || 'console',
    VERSION: process.env.VUE_APP_VERSION,
    BRANCH: process.env.VUE_APP_BRANCH,
    SHA: process.env.VUE_APP_SHA
  }

  return {
    TOKEN,
    API,
    APP
  }
}
