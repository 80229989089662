import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c(VCard,{attrs:{"elevation":"0"}},[_c(VContainer,{staticClass:"pa-0"},[_c(VToolbar,{attrs:{"elevation":"0"}},[[_c(VCol,[_c(VRow,{staticClass:"mb-6"},[_c(VTabs,{staticClass:"tab_heading",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item){return _c(VTab,{key:item,staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1)],1)]],2),_c(VTabsItems,{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.items),function(item,index){return _c(VTabItem,{key:item},[_c(VCard,{attrs:{"flat":""}},[(_vm.tabComponents[item])?_c("keep-alive",{tag:"v-card-text"},[(item === 'All')?_c('All',{attrs:{"data":_vm.dashbordCartData}}):_vm._e(),(item === 'Today')?_c('Today'):_vm._e(),(item === 'This Week')?_c('ThisWeek'):_vm._e(),(item === 'This Month')?_c('ThisMonth'):_vm._e(),(item === 'Last 12 Month')?_c('LastMonth'):_vm._e(),_c(_vm.tabComponents[item],{tag:"component"})],1):_vm._e()],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }