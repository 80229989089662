import { envs } from '../config/envs'
import { useAuth } from '../composable/useAuth'
import { useOrganisations } from '@/composable/repositories/useOrganisations'
import { useStores } from '@/composable/repositories/useStores'
import type { NavigationGuardNext, Route } from 'vue-router'

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  const { store } = to.params
  const { isOverviewMode, currentOrganisation } = useOrganisations()
  const { refreshTenancyKeys } = useAuth()
  const { initStore } = useStores()

  const storeId = Number(store)

  const organisationId = currentOrganisation.value?.id || refreshTenancyKeys.value?.organisation || 0

  if (!isOverviewMode.value && organisationId > 0) {
    await initStore(organisationId, storeId)
  }

  next()
}
