import { api } from '@/libs/api'
import { envs } from '@/config/envs'
import { transformQuery } from '@/libs/transformQuery'
import { useAuth } from '@/composable/useAuth'
import { useRoute } from '@/composable/migrateUseRoute'
import FutureEcom from '@futureecom/futureecom-js/dist/futureecom'
import axios from 'axios'
import isAxiosError from '@/libs/isAxiosError'
import qs from 'qs'
import type { AxiosPromise, AxiosResponse } from 'axios'
import router from '@/plugins/router'

export const getApiConfig = (clientId?: string, organisation?: number): FutureEcom => {
  const { API } = envs()

  const { accessToken, refreshToken, refreshTenancyKeys, setAccessToken, setRefreshToken } = useAuth()

  return new FutureEcom({
    apiUrl: API.URL,

    storeId: API.STORE_ID,
    organisationId: organisation || API.ORGANISATION_ID,
    clientId: clientId || API.CLIENT_ID,
    ws: API.WS_URL ? { url: API.WS_URL, client: WebSocket } : undefined,

    get accessToken() {
      return accessToken.value
    },

    set accessToken(value) {
      setAccessToken(value)
    },

    get refreshToken() {
      return refreshToken.value
    },

    set refreshToken(value) {
      setRefreshToken(value)
    },

    interceptors: {
      response: async (response: AxiosResponse): Promise<AxiosPromise> => {
        if (!isAxiosError(response) || !response.response || ![401, 417].includes(response.response.status)) {
          return Promise.reject(response)
        }
        if (response.response.status) {
          router.push('/')
        }
        
        setAccessToken(undefined)
        if (response.response.status === 401) {
          router.push('/')
        }
        
        if (!refreshToken.value || !refreshTenancyKeys.value) {
          return Promise.reject(response)
        }

        const { organisation, store: id } = refreshTenancyKeys.value

        if (organisation === undefined) {
          return Promise.reject(response)
        }

        const request = id != null ? api.usingStore({ organisation, id }) : api.usingOrganisation(organisation)

        return request.auth
          .refreshAccessToken(refreshToken.value)
          .then((value) => {
            response.config.headers.Authorization = `${value.token_type} ${value.access_token}`
            return axios(response.config)
          })
          .catch(() => {
            // router.push('/')
            api.config.refreshToken = undefined
            return Promise.reject(response)
          })
      }
    },

    paramsSerializer: (params?: any): string => {
      const filter = (prefix: string, value: unknown) => {
        return transformQuery(value, { returnSource: true, emptyArrayString: true, nullString: true })
      }

      return qs.stringify(params, { arrayFormat: 'comma', encode: false, filter })
    }
  })
}

const numberOrUndefined = (val: string | number | undefined): undefined | number => {
  return val === '' || val === undefined ? undefined : Number(val)
}

export const useApi = (resource?: { organisation: number; store?: number }): FutureEcom => {
  const route = useRoute()

  const { organisation: org, store } = resource || route?.params || {}

  const organisation = numberOrUndefined(org)
  const id = numberOrUndefined(store)

  if (organisation !== undefined && id !== undefined) {
    return api.usingStore({ organisation, id })
  }

  if (organisation !== undefined) {
    return api.usingOrganisation(organisation)
  }

  return api || getApiConfig()
}
