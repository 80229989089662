import { computed, ref } from '@vue/composition-api'
import { isOverviewType } from '@/libs/isOverview'
import { useApi } from '@/composable/useApi'
import type { Organisation } from '@futureecom/futureecom-js/dist/services/setting-service'

export const zeroOrganisation: Organisation = {
  id: 0,
  name: 'Overview',
  code: 'overview',
  stores: []
}

const collection = ref<Organisation[]>([])
const currentOrganisation = ref<Organisation | undefined>(isOverviewType() ? zeroOrganisation : undefined)
const loadingOrganisations = ref(false)

export const useOrganisations = () => {
  const fetchOrganisations = (): Promise<void> => {
    loadingOrganisations.value = true

    return new Promise((resolve, reject) => {
      useApi()
        .settings.organisations()
        .then((organisations: Organisation[]) => {
          collection.value = [...organisations]
          resolve()
        })
        .catch((err: any) => reject(err))
        .finally(() => (loadingOrganisations.value = false))
    })
  }

  return {
    fetchOrganisations,
    loadingOrganisations: computed(() => loadingOrganisations.value),
    currentOrganisation,
    collection: computed(() => collection.value),
    isOverviewMode: computed(() => currentOrganisation.value?.id === 0)
  }
}
